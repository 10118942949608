import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { firstValueFrom } from 'rxjs';

import { getCurrencyMetadata } from '../../helpers/currency.helper';
import { DEFAULT_BALANCES } from '../constants/currency';
import { Balance, User } from '../models/user';
import { PayOutService } from '../services/payOut.service';
import { PermissionService } from '../services/permission.service';
import { UserService } from '../services/user.service';

export interface UserState {
  user: User | null;
  permissions: string[];
  roles: string[];
  permissionsStatus: 'LOADED' | 'LOADING' | 'PENDING';
  isLoading: boolean;
  balances: Balance[];
  companyId: string;
}

const initialState: UserState = {
  user: {
    uid: '',
    cognitoId: '',
    email: '',
    firstName: '',
    lastName: '',
    businessName: '',
    nit: 1024540803,
    documentType: '',
    country: {
      id: '',
      countryCode: '',
      countryName: '',
    },
    company: {
      id: '',
      businessName: '',
      documentType: '',
      documentId: '',
      city: null,
      state: null,
      address: null,
      postalCode: null,
      status: '',
      country: {
        id: '',
        countryCode: '',
        countryName: '',
      },
    },
  },
  permissions: [],
  roles: [],
  permissionsStatus: 'PENDING',
  isLoading: false,
  balances: DEFAULT_BALANCES,
  companyId: '',
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ balances }) => ({
    balancesWithMetadata: computed(() =>
      balances().map(balance => ({
        ...balance,
        ...getCurrencyMetadata(balance.currency),
      }))
    ),
  })),
  withMethods(
    (
      store,
      userService = inject(UserService),
      payoutService = inject(PayOutService),
      permissionService = inject(PermissionService)
    ) => ({
      hasPermission: (permission: string) => {
        if (store.permissionsStatus() === 'LOADED') {
          return store.permissions().includes(permission);
        } else {
          throw new Error(
            "You're calling hasPermission before loading the permissions"
          );
        }
      },
      loadPermissionsAndUserData: async () => {
        if (store.permissionsStatus() === 'PENDING' || !store.user()?.uid) {
          patchState(store, { permissionsStatus: 'LOADING' });
          const permissions = await firstValueFrom(
            permissionService.getUserPermissions()
          );
          const currentUser = await firstValueFrom(userService.getUserData());
          const roles = await userService.getUserRoles();
          if (!currentUser) {
            console.error(
              'LOAD_PERMISSIONS: User authenticated not found in db'
            );
            return;
          }
          patchState(store, {
            permissions,
            roles,
            companyId: currentUser.company.id,
            user: currentUser,
          });
          patchState(store, { permissionsStatus: 'LOADED' });
        }
      },
      reloadUserData: async () => {
        const currentUser = await firstValueFrom(userService.getUserData());
        patchState(store, {
          user: currentUser,
        });
      },
      loadBalances: async () => {
        const balances = await firstValueFrom(payoutService.getUserBalances());
        if (balances?.length) {
          patchState(store, {
            balances,
          });
        }
      },
      resetUserData: async () => {
        patchState(store, initialState);
      },
    })
  )
);
