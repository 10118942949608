import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiResponseWithMeta } from '../models/api';
import { PaginationEvent } from '../models/table';
import { NewUserPayload, Role, User, UserOnboarding } from '../models/user';
import { fetchAuthSession } from 'aws-amplify/auth';
import { GlobalErrorHandlerService } from './global-error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);
  private errorService = inject(GlobalErrorHandlerService);

  getUserData(): Observable<User> {
    return this.http.get<User>(`${environment.supraApi}/v1/user/me`);
  }

  getAllUsers(
    pagination?: PaginationEvent
  ): Observable<ApiResponseWithMeta<User>> {
    const params = {
      limit: pagination?.pageSize.toString() || '10',
      page: pagination?.pageIndex ? (pagination.pageIndex + 1).toString() : '1',
    };
    return this.http.get<ApiResponseWithMeta<User>>(
      `${environment.supraApi}/v1/user/company/list`,
      { params }
    );
  }

  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${environment.supraApi}/v1/role/list`);
  }

  createUser(user: NewUserPayload): Observable<User> {
    return this.http.post<User>(
      `${environment.supraApi}/v1/user/company`,
      user
    );
  }

  editUser(userId: string, user: NewUserPayload): Observable<string> {
    return this.http.put<string>(
      `${environment.supraApi}/v1/user/company/${userId}`,
      user
    );
  }

  changeUserStatus(
    userId: string,
    status: 'active' | 'disabled'
  ): Observable<string> {
    return this.http.put<string>(
      `${environment.supraApi}/v1/user/company/status/${userId}/${status}`,
      {}
    );
  }

  updateUserRole(userId: string, roleSlug: string): Observable<string> {
    return this.http.put<string>(
      `${environment.supraApi}/v1/user/company/role/${userId}`,
      { roleSlug }
    );
  }

  completeOnboarding(): Observable<string> {
    return this.http.post<string>(
      `${environment.supraApi}/v1/user/complete-onboarding`,
      {}
    );
  }

  startUserOnboarding(user: UserOnboarding): Observable<string> {
    return this.http.post<string>(
      `${environment.apiGatewayUrl}onboarding`,
      user
    );
  }

  async getUserRoles(): Promise<string[]> {
    try {
      const user = await fetchAuthSession();
      const groups = user.tokens?.idToken?.payload[
        'cognito:groups'
      ] as string[];
      return groups || [];
    } catch (error) {
      this.errorService.logError(error);
      return [];
    }
  }
}
