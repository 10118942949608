import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserStore } from '../store/user-store';

export const MainGuard: CanActivateFn = async (route, state) => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  await userStore.loadPermissionsAndUserData();
  if (
    userStore.roles().includes('viewer') &&
    userStore.user()?.company.status === 'created' &&
    state.url !== '/dashboard/new-user-landing'
  ) {
    await router.navigate(['/dashboard/new-user-landing']);
    return false;
  }
  if (
    userStore.roles().includes('super-admin') &&
    userStore.user()?.isOnboarded === false &&
    state.url !== '/admin/new-user-onboarding'
  ) {
    await router.navigate(['/admin/new-user-onboarding']);
    return false;
  }
  return true;
};
