import { ErrorHandler, inject, Injectable } from '@angular/core';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private http = inject(HttpClient);

  async handleError(error: Error) {
    await this.logError(error, 'GLOBAL');
  }

  async logError(error: Error | unknown, type: 'GLOBAL' | 'LOCAL' = 'LOCAL') {
    const isLocalhost = window.location.hostname === 'localhost';
    if (isLocalhost) {
      console.log('Log error skipped in localhost environment:', {
        error,
        type,
      });
      return;
    }
    const errorLog = {
      type,
      timestamp: new Date().toISOString(),
      message:
        (error instanceof Error ? error?.message : this.safeStringify(error)) ||
        'Unknown error',
      stack: error instanceof Error ? error?.stack : 'No stack available',
      url: window.location.href,
      additionalData: await this.getAdditionalContext(),
    };
    await firstValueFrom(
      this.http.post(`${environment.apiGatewayUrl}process-log`, errorLog)
    );
  }

  private safeStringify = (obj: unknown): string => {
    try {
      return JSON.stringify(obj);
    } catch {
      return '{"error": "Unserializable object"}';
    }
  };

  private async getAdditionalContext(): Promise<Record<string, unknown>> {
    const isAuthenticated = await this.isAuthenticated();
    return {
      userContext: await this.getUserContext(isAuthenticated),
      appState: await this.getAppState(isAuthenticated),
      deviceInfo: this.getDeviceInfo(),
      browserInfo: this.getBrowserInfo(),
    };
  }

  private getBrowserInfo(): Record<string, boolean | string> {
    return {
      cookiesEnabled: navigator.cookieEnabled,
      localStorageAvailable: typeof localStorage !== 'undefined',
      indexedDBAvailable: typeof indexedDB !== 'undefined',
      userAgent: navigator.userAgent,
    };
  }

  private async getAppState(
    isAuthenticated: boolean
  ): Promise<Record<string, string | boolean>> {
    return {
      currentRoute: window.location.pathname,
      isPublic: !isAuthenticated,
    };
  }

  private getDeviceInfo(): Record<string, string> {
    return {
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      orientation: window.screen.orientation?.type || 'unknown',
      connectionType:
        (navigator as unknown as { connection?: { effectiveType: string } })
          .connection?.effectiveType || 'unknown',
      deviceType: this.getDeviceType(),
    };
  }

  private getDeviceType(): string {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/mobile|android|iphone|ipad/.test(userAgent)) return 'mobile';
    if (/tablet/.test(userAgent)) return 'tablet';
    return 'desktop';
  }

  private async getUserContext(
    isAuthenticated: boolean
  ): Promise<Record<string, string | boolean | null> | null> {
    if (isAuthenticated) {
      const user = await fetchUserAttributes();
      return user as Record<string, string | boolean>;
    } else {
      return null;
    }
  }

  private async isAuthenticated(): Promise<boolean> {
    const authSession = await fetchAuthSession();
    return !!(authSession.tokens && authSession?.tokens.accessToken);
  }
}
