import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const AuthGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const authToken = await authService.getAuthToken();
  if (authToken) {
    return true;
  }
  await router.navigate(['/auth/login']);
  return false;
};
