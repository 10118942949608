import { ActivatedRouteSnapshot } from '@angular/router';
import { inject } from '@angular/core';

import { UserStore } from '../store/user-store';

export const hasPermissionGuard: (
  route: ActivatedRouteSnapshot
) => Promise<boolean> = async (route: ActivatedRouteSnapshot) => {
  const userStore = inject(UserStore);
  await userStore.loadPermissionsAndUserData();
  if (route.data?.['permission']) {
    return userStore.hasPermission(route.data?.['permission']);
  }
  return true;
};
