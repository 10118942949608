import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/components/dump-components/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { hasPermissionGuard } from './core/guards/has-permission.guard';
import { MainGuard } from './core/guards/main.guard';
import { AuthLayoutComponent } from './layouts/components/smart-components/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/components/smart-components/main-layout/main-layout.component';
import { redirectIfAuthenticatedGuard } from './core/guards/redirect-if-authenticated.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivateChild: [redirectIfAuthenticatedGuard],
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./modules/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, MainGuard],
    canMatch: [hasPermissionGuard],
    data: {
      permission: 'user-management',
    },
    loadChildren: () =>
      import('./modules/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [MainGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
      },
      {
        path: 'conversion',
        canMatch: [hasPermissionGuard],
        data: {
          permission: 'conversion-management',
        },
        loadChildren: () =>
          import('./modules/conversion/conversion.module').then(
            m => m.ConversionModule
          ),
      },
      {
        path: 'recipient',
        canMatch: [hasPermissionGuard],
        data: {
          permission: 'beneficiary-management',
        },
        loadChildren: () =>
          import('./modules/recipient/recipient.module').then(
            m => m.RecipientModule
          ),
      },
      {
        path: 'international-collection',
        canMatch: [hasPermissionGuard],
        data: {
          permission: 'collection-management',
        },
        loadChildren: () =>
          import(
            './modules/international-collection/international-collection.module'
          ).then(m => m.InternationalCollectionModule),
      },
      {
        path: 'payout',
        canMatch: [hasPermissionGuard],
        data: {
          permission: 'transfer-preparation',
        },
        loadChildren: () =>
          import('./modules/payout/payout.module').then(m => m.PayoutModule),
      },
      {
        path: 'pay-in',
        canMatch: [hasPermissionGuard],
        data: {
          permission: 'conversion-management',
        },
        loadChildren: () =>
          import('./modules/pay-in/pay-in.module').then(m => m.PayInModule),
      },
      {
        path: 'user',
        canMatch: [hasPermissionGuard],
        loadChildren: () =>
          import('./modules/user/user.module').then(m => m.UserModule),
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
