export const environment = {
  production: false,
  appVersion: '1.0.0',
  supraApi: 'https://api-dev.supra.la',
  cognitoUserPoolId: 'us-east-2_PdnFB1gDd',
  cognitoClientId: '7ta9m3nlgq8e50mcre3equs6lj',
  cobreRedirectUrl: 'https://dev-app.supra.la/conversion/payment-confirmation',
  complianceUrl: 'https://onboarding.supra.la',
  apiGatewayUrl: 'https://dev.api-public.supra.la/',
};
