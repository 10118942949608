/* src/app/core/components/dump-components/page-not-found/page-not-found.component.scss */
.page-not-found {
  text-align: center;
  margin: 50px auto;
  background-color: #F6F7F9;
}
.page-not-found h1 {
  font-size: 2rem;
  color: #D92D21;
}
.page-not-found p {
  margin-top: 10px;
  font-size: 1.1rem;
  color: #929EAA;
}
/*# sourceMappingURL=page-not-found.component.css.map */
