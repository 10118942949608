import { CurrencyMetadata } from '../models/currency';

export const DEFAULT_BALANCES = [
  {
    currency: 'cop',
    amount: 0,
  },
  {
    currency: 'usd',
    amount: 0,
  },
  {
    currency: 'eur',
    amount: 0,
  },
];

export const CURRENCY_METADATA_MAP: Record<string, CurrencyMetadata> = {
  USD: {
    country: 'united-states',
    flagUrl: 'united-states.png',
    symbol: '$',
  },
  EUR: {
    country: 'european-union',
    flagUrl: 'european-union.png',
    symbol: '€',
  },
  COP: {
    country: 'colombia',
    flagUrl: 'colombia.png',
    symbol: '$',
  },
  MXN: {
    country: 'mexico',
    flagUrl: 'mexico.png',
    symbol: '$',
  },
  BRL: {
    country: 'brazil',
    flagUrl: 'brazil.png',
    symbol: 'R$',
  },
  THB: {
    country: 'thailand',
    flagUrl: 'thailand.png',
    symbol: '฿',
  },
  AUD: {
    country: 'australia',
    flagUrl: 'australia.png',
    symbol: '$',
  },
  CAD: {
    country: 'canada',
    flagUrl: 'canada.png',
    symbol: '$',
  },
  HKD: {
    country: 'hong-kong',
    flagUrl: 'hong-kong.png',
    symbol: '$',
  },
  GBP: {
    country: 'united-kingdom',
    flagUrl: 'united-kingdom.png',
    symbol: '£',
  },
  TRY: {
    country: 'turkey',
    flagUrl: 'turkey.png',
    symbol: '₺',
  },
  ARS: {
    country: 'argentina',
    flagUrl: 'argentina.png',
    symbol: '$',
  },
  CLP: {
    country: 'chile',
    flagUrl: 'chile.png',
    symbol: '$',
  },
  UYU: {
    country: 'uruguay',
    flagUrl: 'uruguay.png',
    symbol: '$',
  },
  INR: {
    country: 'india',
    flagUrl: 'india.png',
    symbol: '₹',
  },
  PEN: {
    country: 'peru',
    flagUrl: 'peru.png',
    symbol: 'S/',
  },
  BDT: {
    country: 'bangladesh',
    flagUrl: 'bangladesh.png',
    symbol: '৳',
  },
  JPY: {
    country: 'japan',
    flagUrl: 'japan.png',
    symbol: '¥',
  },
  CNY: {
    country: 'china',
    flagUrl: 'china.png',
    symbol: '¥',
  },
  CHF: {
    country: 'switzerland',
    flagUrl: 'switzerland.png',
    symbol: 'CHF',
  },
};
