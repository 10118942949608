import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { Country } from '../core/models/country';

export function translateAndSortCountries(
  response: { countries: Country[] },
  translateService: TranslateService
): Observable<{ countries: Country[] }> {
  const translationKeys = response.countries.map(
    country => `countries.${country.countryCode}`
  );

  return translateService.get(translationKeys).pipe(
    map(translations => ({
      countries: response.countries
        .map(country => {
          const translation = translations[`countries.${country.countryCode}`];
          const translatedName =
            translation && !translation.includes('countries.')
              ? translation
              : country.countryName;
          return { ...country, countryName: translatedName };
        })
        .sort((a, b) => a.countryName.localeCompare(b.countryName)),
    }))
  );
}
