import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const redirectIfAuthenticatedGuard: CanActivateFn = async (
  route,
  state
) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  if (state.url.includes('clear-cache')) {
    return true;
  }
  const authToken = await authService.getAuthToken();
  if (authToken) {
    await router.navigate(['/dashboard']);
  }
  return true;
};
