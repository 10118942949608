<nav class="sidebar-menu" role="navigation" aria-label="Main Navigation">
  <div class="sidenav__logo" aria-hidden="true">
    <a routerLink="/dashboard">
      <img
        ngSrc="/img/logo-white-small.png"
        alt="Logo Supra"
        height="35"
        width="118"
        priority />
    </a>
  </div>
  <ul class="sidebar-menu__list">
    <li class="sidebar-menu__item">
      <a
        routerLink="/dashboard"
        [attr.aria-label]="'sidebar.menu.home' | translate"
        (click)="handleCloseMenu()"
        (keydown.enter)="handleCloseMenu()"
        tabindex="0">
        <mat-icon>home</mat-icon>
        <span>{{ 'sidebar.menu.home' | translate }}</span>
      </a>
    </li>
    @if (userStore.hasPermission('beneficiary-management')) {
      <li class="sidebar-menu__item">
        <a
          routerLink="/recipient"
          [attr.aria-label]="'sidebar.menu.recipients' | translate"
          (click)="handleCloseMenu()"
          (keydown.enter)="handleCloseMenu()"
          tabindex="0">
          <mat-icon>group</mat-icon>
          <span>{{ 'sidebar.menu.recipients' | translate }}</span>
        </a>
      </li>
    }
    @if (userStore.hasPermission('transfer-preparation')) {
      <li class="sidebar-menu__item">
        <a
          routerLink="/payout/transfers"
          [attr.aria-label]="'sidebar.menu.home' | translate"
          (click)="handleCloseMenu()"
          (keydown.enter)="handleCloseMenu()"
          tabindex="1">
          <mat-icon>sync_alt</mat-icon>
          <span>{{ 'sidebar.menu.transfers' | translate }}</span>
        </a>
      </li>
    }
    @if (userStore.hasPermission('collection-management')) {
      <li class="sidebar-menu__item">
        <a
          routerLink="/international-collection"
          [attr.aria-label]="'sidebar.menu.internationalCollection' | translate"
          (click)="handleCloseMenu()"
          (keydown.enter)="handleCloseMenu()"
          tabindex="2">
          <mat-icon>account_balance_wallet</mat-icon>
          <span>{{ 'sidebar.menu.internationalCollection' | translate }}</span>
        </a>
      </li>
    }
    @if (userStore.hasPermission('user-management')) {
      <li class="hide-mobile sidebar-menu__item">
        <a
          routerLink="/admin/users"
          [attr.aria-label]="'sidebar.menu.userManagement' | translate"
          (click)="handleCloseMenu()"
          (keydown.enter)="handleCloseMenu()"
          tabindex="2">
          <mat-icon>groups</mat-icon>
          <span>{{ 'sidebar.menu.userManagement' | translate }}</span>
        </a>
      </li>
    }
  </ul>
  <ul class="sidebar-menu__list">
    <li class="sidebar-menu__item">
      <a
        routerLink="/user/profile"
        routerLinkActive="active"
        [attr.aria-label]="'sidebar.menu.profile' | translate"
        (click)="handleCloseMenu()"
        (keydown.enter)="handleCloseMenu()"
        tabindex="3">
        <mat-icon>person</mat-icon>
        <span>{{ 'sidebar.menu.profile' | translate }}</span>
      </a>
    </li>
    <li class="sidebar-menu__item">
      <button
        mat-button
        color="primary"
        [attr.aria-label]="'sidebar.menu.signOut' | translate"
        (click)="signOut()">
        <mat-icon>logout</mat-icon>
        <span>{{ 'sidebar.menu.signOut' | translate }}</span>
      </button>
    </li>
  </ul>
</nav>
