import { inject, Injectable } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  authService = inject(AuthService);

  async clearAppCache() {
    if ('caches' in window) {
      const keyList = await caches.keys();
      for (const key of keyList) {
        await caches.delete(key);
      }
    }
    localStorage.clear();
    sessionStorage.clear();
    try {
      await this.authService.signOut();
      window.location.href = '/auth/login';
    } catch {
      window.location.href = '/auth/login';
    }
  }
}
