import { Component, inject, output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { AuthService } from '../../../../core/services/auth.service';
import { UserStore } from '../../../../core/store/user-store';
import { CacheService } from '../../../../shared/services/cache.service';

@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatIcon,
    RouterLinkActive,
    TranslateModule,
    NgOptimizedImage,
    MatButton,
  ],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss',
})
export class SidebarMenuComponent {
  closeMenu = output();
  authService = inject(AuthService);
  userStore = inject(UserStore);
  cacheService = inject(CacheService);

  handleCloseMenu() {
    this.closeMenu.emit();
  }

  async signOut() {
    await this.authService.signOut();
    await this.userStore.resetUserData();
    await this.cacheService.clearAppCache();
  }
}
