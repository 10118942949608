import { CURRENCY_METADATA_MAP } from '../core/constants/currency';
import { CurrencyMetadata } from '../core/models/currency';

export function getCurrencyMetadata(currency: string): CurrencyMetadata {
  const currencyLower = currency.toUpperCase();
  const defaultMetadata: CurrencyMetadata = {
    country: '',
    flagUrl: 'supra.png',
    symbol: '',
  };
  return CURRENCY_METADATA_MAP[currencyLower] || defaultMetadata;
}
