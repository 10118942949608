<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    [mode]="isMobile() ? 'over' : 'side'"
    [opened]="!isMobile()"
    class="sidenav">
    <app-sidebar-menu
      (closeMenu)="isMobile() && sidenav.close()"></app-sidebar-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    @if (isMobile()) {
      <mat-toolbar color="primary" class="toolbar-fixed">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>

        <span>Supra</span>
      </mat-toolbar>
    }

    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
