import { environment } from './environments/environment';

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: environment.cognitoUserPoolId,
  aws_user_pools_web_client_id: environment.cognitoClientId,
};

export default awsmobile;
